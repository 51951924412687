export interface AppCoachResourcesExercisesListFormUpdate {
  id: string;
  organization_id: string;
  content: {
    name: string;
    type: string;
    description: string;
    url: string;
  };
}

export class AppCoachResourcesExercisesListFormUpdate implements AppCoachResourcesExercisesListFormUpdate {
  id: string;
  organization_id: string;
  content: {
    name: string;
    type: string;
    description: string;
    url: string;
  };

  constructor() {
    this.id = "";
    this.organization_id = "";
    this.content = {
      name: "",
      type: "",
      description: "",
      url: "",
    };
  }
}